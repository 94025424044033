import styled from "styled-components";

export const Container = styled.div`
    max-width: 445px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        color: ${({ theme }) => theme.colors.gray.text_base};
    }
`

export const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

export const Content = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.secondary.health_green};
        display: flex; 
        justify-content: center;
        align-items: center;
    }

    .text {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        flex-direction: column;

        .text-label {
            font-weight: 600;
            color: ${({ theme }) => theme.colors.black.main};
        }

        .text-content {
            font-weight: 400;
            color: ${({ theme }) => theme.colors.gray.dark};
        }
    }
`

export const Divider = styled.div`
    width: 0;
    height: 32px;
    border: 1px solid ${({ theme }) => theme.colors.gray.dark};
    margin-left: 20px;
`