import React from "react"
import { Container, BoxContent, Content, Divider } from "./styles"
import CheckIcon from "../../../assets/icons/Check"

/**
Componente para renderizar uma lista de checagem
@param {string} title - Título da lista de checagem
@param {array} data - Lista de itens (objetos) com atributos label e content (texto) 

@returns {JSX.Element} Componente de lista de checagem

@example
<CheckList
    title="Título da check list"
    data={[{label: "Label do item 1", content: "Conteúdo do item 1"}, 
    {label: "Label do item 2", content: "Conteúdo do item 2"}]}
/>
 */
export default function CheckList({ title, data = [] }) {
    return(
        <Container>
            <h1>{title}</h1>
            <div>
                {data.map((item, key) => (
                    <BoxContent key={key}>
                        <Content>
                            <div className="icon">
                                <CheckIcon/>
                            </div>
                            <div className="text">
                                <span className="text-label">{item.label}</span>
                                <span className="text-content">{item.content}</span>
                            </div>
                        </Content>
                        {key !== data.length - 1 && <Divider/>}
                    </BoxContent>
                ))}
            </div>
        </Container>
    )
}