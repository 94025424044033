import React, { useState } from "react"
import styled from "styled-components"
import { Container } from "../../components/compartilhados/Container"
import CheckList from "../../components/profissionaisSaude/CheckList"
import Header from "../../components/profissionaisSaude/Header"
import Footer from "../../components/profissionaisSaude/Footer"
import Doctors from "../../assets/images/doctors.svg"
import Button from "../../components/profissionaisSaude/Inputs/Button"

const MainContentWrapper = styled.div `
    min-height: calc(100vh - 212px);
    margin-top: ${({ theme }) => theme.spacing['2xl']}; 
`

const ContentWrapper = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

const DataWrapper = styled.section ``

const ImageWrapper = styled.section `
    max-width: 475px;
    max-height: 321px;

    .main-image {
        width: 100%;
        height: 100%;
    }
`

const ButtonsWrapper = styled.section `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0 75px 0;
    width: 100%;

    a {
        max-width: 261px;
    }
`

export default function CadastroConfirmarRespostas() {
    const [ data, setData ] = useState([
        {label: "Nome Civil ou Social completo", content:"Resposta"},
        {label: "E-mail", content:"Resposta"},
        {label: "Profissão", content:"Resposta"},
        {label: "Número de inscrição profissional", content:"Resposta"}
    ])

    return(
        <Container>
            <Header/>
            <MainContentWrapper>
                <ContentWrapper>
                    <DataWrapper>
                        <CheckList title="Confira seus dados antes de confirmar" data={data}/>
                    </DataWrapper>
                    <ImageWrapper>
                        <Doctors className="main-image"/>
                    </ImageWrapper>
                </ContentWrapper>
                <ButtonsWrapper>
                    <Button title="Corrigir" variant="outlined"/>
                    <Button title="Confirmar"/>
                </ButtonsWrapper>
            </MainContentWrapper>
            <Footer/>
        </Container>
    )
}